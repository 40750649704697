import * as yup from 'yup';

import {
  Box,
  Grid,
  useTheme,
  Hidden,
  Button,
} from '@material-ui/core';
import { TypographyElement, useContentElement, LinkElement } from '@plugins/next-cms-core';
import { gql, useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import CampaignPreviewItem from '../molecules/CampaignPreviewItem';
import BlogPreviewItem from '../molecules/BlogPreviewItem';
import Container from '../atoms/Container';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

const PAGE_QUERY_PARAM = 'csp';

export default function CampaignBlock(props) {
  const { data } = props;
  const router = useRouter();
  const classes = useStyles();
  const {
    t,
    i18n,
  } = useTranslation();
  const [swiper, setSwiper] = useState();
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const { elementData } = useContentElement(
    data,
    CampaignBlock.dataSchema,
  );

  const {
    campaignDisplay: display,
    campaignBlockTags: tags,
    limit,
    moreLink,
  } = elementData;
  const tagIds = tags.data.map((tag) => tag.id);

  // Additional data
  const {
    error,
    data: additionalData,
    loading,
  } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      locale: i18n.language,
      tags: tagIds,
      limit: display === 'slider' ? 100 : limit || 4,
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (!swiper || !additionalData) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (error) {
    throw error;
  }

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  if (!tags || tags.length === 0 || loading) {
    return null;
  }

  const items = additionalData.pages.data ?? [];

  const updatePageQueryParam = (index) => {
    const queryParams = new URLSearchParams(window.location.search);
    const hasQueryParam = queryParams.has(PAGE_QUERY_PARAM);
    queryParams.set(PAGE_QUERY_PARAM, index);
    const updatedPathname = `${router.asPath.split('?')[0]}?${queryParams.toString()}`;
    router[hasQueryParam ? 'replace' : 'push'](updatedPathname, undefined, { shallow: true });
    setCanGoPrevious(index > 0);
    setCanGoNext(index < swiper.snapGrid.length - 1);
  };

  const handleGoToPreviousPage = () => {
    updatePageQueryParam(swiper.activeIndex - 1);
  };
  const handleGoToNextPage = () => {
    updatePageQueryParam(swiper.activeIndex + 1);
  };
  const handleSlideChange = () => {
    updatePageQueryParam(swiper.activeIndex);
  };

  return (
    <div className={classes.root}>
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        {display !== 'slider' && <List classes={classes} items={items} />}
        {display === 'slider' && (
          <Box className={classes.itemsContainer}>
            <Hidden smDown>
              <Button
                className={clsx(
                  classes.paginationButton,
                  classes.paginationButtonLeft,
                )}
                disabled={!canGoPrevious}
                onClick={handleGoToPreviousPage}
                size="small"
              >
                <FiArrowLeft className={classes.paginationButtonIcon} />
              </Button>
            </Hidden>
            <Slider
              classes={classes}
              items={items}
              onActiveIndexChange={handleSlideChange}
              setSwiper={setSwiper}
            />
            <Hidden smDown>
              <Button
                className={clsx(
                  classes.paginationButton,
                  classes.paginationButtonRight,
                )}
                disabled={!canGoNext}
                onClick={handleGoToNextPage}
                size="small"
              >
                <FiArrowRight className={classes.paginationButtonIcon} />
              </Button>
            </Hidden>
          </Box>
        )}
        {moreLink && (
          <Box className={classes.moreLinkContainer}>
            <LinkElement data={moreLink}>
              <Button
                color="primary"
                component="a"
                variant="contained"
              >
                {t('components.contentTypes.CampaignBlock.showMore')}
              </Button>
            </LinkElement>
          </Box>
        )}
      </Container>
    </div>
  );
}

function List(props) {
  const {
    items,
    classes,
  } = props;

  return (
    <Grid
      alignItems="stretch"
      container
      justifyContent="center"
      spacing={7}
    >
      {items.map((item) => (
        <Grid
          key={item.id}
          className={classes.tileContainer}
          item
          md={6}
          xs={12}
        >
          <CampaignPreviewItem item={item} />
        </Grid>
      ))}
    </Grid>
  );
}

function Slider(props) {
  const {
    items,
    classes,
    setSwiper,
    onActiveIndexChange,
  } = props;
  const theme = useTheme();

  const onSlideChangeHandler = (swiper) => {
    swiper.pagination.bullets.forEach((item, index) => {
      if (index < swiper.activeIndex) {
        item.className = 'swiper-pagination-bullet swiper-pagination-bullet-active';
      }
    });
  };

  return (
    <Swiper
      autoHeight
      className={classes.swipeContainer}
      modules={[Pagination]}
      onActiveIndexChange={onActiveIndexChange}
      onSlideChange={onSlideChangeHandler}
      onSwiper={(swiper) => setSwiper(swiper)}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={theme.spacing(2.5)}
      virtual={{
        addSlidesAfter: 3,
        addSlidesBefore: 3,
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide
          key={item.id}
          className={classes.swipeSlideItem}
          virtualIndex={index}
        >
          <BlogPreviewItem item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  paginationButton: {
    position: 'absolute',
    display: 'block',
    width: 50,
    minWidth: 0,
    top: 7,
    bottom: 30,
  },
  paginationButtonLeft: {
    left: -50 - theme.spacing(1),
  },
  paginationButtonRight: {
    right: -50 - theme.spacing(1),
  },
  paginationButtonIcon: {
    width: 20,
    height: 20,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    paddingBottom: theme.spacing(5),
  },
  itemsContainer: {
    position: 'relative',
  },
  swipeContainer: {
    padding: 7,
    margin: -7,
    paddingTop: 20,
    '& .swiper-wrapper': {
      alignItems: 'stretch',
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.primary.main,
    },
    '& .swiper-container': {
      position: 'relative',
    },

    '& .swiper-pagination': {
      position: 'absolute',
      top: 0,
      display: 'flex',
      height: '10px',
      zIndex: 15,
    },

    '& .swiper-pagination-bullet': {
      borderRadius: 15,
      width: '100%',
      background: theme.palette.primary.main,

    },

    swipeSlideItem: {
      height: 'auto',
      display: 'flex',
    },
  },
  moreLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
  },
}));

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

CampaignBlock.typeName = 'ComponentContentCampaignPreviews'; // Strapi element type
CampaignBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    campaignDisplay: PropTypes.oneOf([null, 'list', 'slider']),
    campaignBlockTags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    limit: PropTypes.number.isRequired,
    moreLink: LinkElement.propTypes,
  }).isRequired,
};
CampaignBlock.dataSchema = yup.object()
  .shape({
    id: yup.number()
      .required(),
    title: TypographyElement.dataSchema.nullable(),
    campaignDisplay: yup.string()
      .oneOf([null, 'list', 'slider'])
      .nullable(),
    campaignBlockTags: yup.array(yup.object()
      .shape({
        id: yup.number()
          .required(),
      }))
      .nullable(),
    limit: yup.number()
      .nullable(),
    moreLink: LinkElement.dataSchema.nullable(),
  });
CampaignBlock.graphQlSchema = `
... on ${CampaignBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  campaignDisplay: display
  campaignBlockTags: tags {
    data {
      id
    }
  }
  limit
  moreLink {
    ${LinkElement.graphQlSchema}
  }
}
`;

const FETCH_ADDITIONAL_DATA = gql`
  query GetBlogItems($locale: I18NLocaleCode!, $tags: [ID], $limit: Int!) {
    pages(
      filters: {
        pageType: { in: ["blogItem", "campaignItem"] },
        tags: { id: { in: $tags } }
      },
      sort: ["isPinnedToTop:desc", "publishDate:desc", "publishedAt:desc"],
      locale: $locale,
      pagination: { limit: $limit }
    ) {
      data {
        ${CampaignPreviewItem.graphQlSchema}
      }
    }
  }
`;
